<app-tri-panel-modal *ngIf="move$ | async as move; else noMove">
    <ng-container modal-header>
        <h5>{{ move | userName }}</h5>
    </ng-container>

    <ng-container modal-left>
        <app-transferee-detail [move]="move" class="u-margin-bottom-double" (moveChange)="updateMove($event, true)">
        </app-transferee-detail>

        <app-moving-address-detail [move]="move" class="u-margin-bottom"></app-moving-address-detail>

        <app-moving-date-detail [move]="move" class="u-margin-bottom-double"></app-moving-date-detail>

        <h6 class="u-margin-bottom-half u-color-muted">{{ 'MOVE.MOVER_ROLE.LEAVER' | translate }}</h6>
        <div *ngIf="!!move.linkedMove; else noLinkedMove" class="u-flex-column u-flex-align-items-start">
            <span>{{ move.linkedMove.user?.formattedName }}</span>
            <span>{{ move.linkedMove.user?.phoneNumber }}</span>
            <a class="u-link" [href]="move.linkedMove.user?.email | uriSchema: mailTo">{{ move.linkedMove.user?.email }}</a>
            <a class="u-link u-margin-top" (click)="showLeaver(move.linkedMove._id)"
                >{{ 'MOVE.MOVER_ROLE.LEAVER.VIEW_DETAILS' | translate }}</a
            >
        </div>

        <ng-template #noLinkedMove>
            <div class="u-flex-column u-flex-align-items-start">
                <span class="u-color-muted u-opacity-50">{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.LEAVER_UNKNOWN' | translate }}</span>
                <a class="u-link u-margin-top" (click)="addLeaver()">{{ 'MOVE.MOVER_ROLE.LEAVER.ADD' | translate }}</a>
            </div>
        </ng-template>
    </ng-container>

    <ng-container modal-center>
        <app-menu>
            <app-menu-item [readonly]="true">
                <div class="u-flex-row u-flex-justify-content-space-between u-flex-align-items-center">
                    <span>{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.SERVICES' | translate }}</span>
                    <app-service-dropdown-menu [move]="move" (reload)="fetch(data.id)" class="u-position-right"></app-service-dropdown-menu>
                </div>
            </app-menu-item>

            <ng-container *ngFor="let service of availableServices; trackBy: trackByService">
                <app-menu-item
                    size="sm"
                    *ngIf="service.isVisible(move)"
                    (click)="changeTab(service.tab)"
                    [active]="activeTab === service.tab"
                    [level]="1"
                >
                    {{ service.label | translate }}
                    <sup *ngIf="hasServiceTodo(move.todosForRealEstateAgent, service.todoList)">
                        <app-icon icon="error" class="u-color-danger"></app-icon>
                    </sup>
                </app-menu-item>
            </ng-container>

            <app-menu-item (click)="changeTab(tabs.contactLogs)" [active]="activeTab === tabs.contactLogs">
                {{ 'MOVE.CONTACT_LOGS_REAL_ESTATE_AGENT.TITLE.MOBILE' | translate }}
            </app-menu-item>

            <app-menu-item (click)="changeTab(tabs.nps)" [active]="activeTab === tabs.nps">
                {{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.NPS.TITLE' | translate }}
            </app-menu-item>
        </app-menu>
    </ng-container>

    <ng-container modal-right>
        <ng-container [ngSwitch]="activeTab">
            <ng-container *ngSwitchCase="tabs.energy">
                <h3 class="u-margin-bottom">{{ 'ENERGY.LABEL' | translate }}</h3>
                <app-move-track-energy></app-move-track-energy>
            </ng-container>

            <app-move-detail-telecom *ngSwitchCase="tabs.telecom" [move]="move"></app-move-detail-telecom>

            <ng-container *ngSwitchCase="tabs.water">
                <h3 class="u-margin-bottom">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.TITLE' | translate }}</h3>
                <app-move-detail-water-section (moveUpdate)="reload()"></app-move-detail-water-section>
            </ng-container>

            <ng-container *ngSwitchCase="tabs.insurances">
                <h3 class="u-margin-bottom">{{ 'INSURANCES.LABEL' | translate }}</h3>
                <app-move-detail-insurances [move]="move"></app-move-detail-insurances>
            </ng-container>

            <ng-container *ngIf="activeTab === tabs.contactLogs">
                <h3 class="u-margin-bottom">{{ 'MOVE.CONTACT_LOGS_REAL_ESTATE_AGENT.TITLE.DESKTOP' | translate }}</h3>
                <app-contact-logs-overview [move]="move"></app-contact-logs-overview>
            </ng-container>

            <ng-container *ngIf="activeTab === tabs.nps">
                <h3 class="u-margin-bottom">{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.NPS.TITLE' | translate }}</h3>
                <app-move-nps-detail [move]="move"></app-move-nps-detail>
                <app-gift-track-and-trace
                    *ngIf="(move.orders | orderGift) as order; else sendGiftBox"
                    [order]="order"
                    class="u-margin-top-triple u-display-block"
                ></app-gift-track-and-trace>
                <ng-template #sendGiftBox>
                    <app-send-gift-box
                        *hasFeatureAccess="[featureScope.Gift]"
                        (reload)="reload()"
                        [move]="move"
                        class="u-margin-top-triple u-display-block"
                    ></app-send-gift-box>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="activeTab === tabs.boilerMaintenance">
                <h3 class="u-margin-bottom">{{ 'SERVICES.BOILER_MAINTENANCE.TITLE' | translate }}</h3>
                <app-move-detail-boiler-maintenance-section></app-move-detail-boiler-maintenance-section>
            </ng-container>
        </ng-container>
    </ng-container>
</app-tri-panel-modal>

<ng-template #noMove>
    <div class="u-w60vw u-flex-column u-flex-align-items-start">
        <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>
</ng-template>
