<ng-container *ngIf="move">
    <app-todo-list
        *ngIf="{
            overallState:
                (!move.track.trackMeterReadings || move.track.energyMeterReadings.completed === todoState.Checked) &&
                (move.track.transferee.completed === todoState.Checked || energyStopStore.energyStop()?.status === ServiceStatus.Submitted),
        } as state"
        [completed]="state.overallState"
    >
        <app-todo
            [checked]="state.overallState"
            [label]="overalStateLabelI8n | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard"
            [variant]="headerVariant.H4"
        >
        </app-todo>
        <div class="u-flex-row" todo-list-footer>
            <app-icon [size]="iconSize.Xm" class="u-color-info" icon="info"></app-icon>
            <div class="u-flex-column u-margin-left-half">
                <div
                    [class.u-margin-top-xs]="!showFurtherAssistanceLabel"
                    [innerHtml]="overalStateI18n | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard"
                ></div>
                <div *ngIf="showFurtherAssistanceLabel" class="u-flex-row u-margin-top-xs">
                    <span>{{
                        'DETAIL.STATUS.METER_READINGS_COMPLETE.FURTHER_ASSISTANCE' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard
                    }}</span>
                    <a [href]="moveSandbox.meterInfoTransfereeContactUsLink$ | async" class="u-margin-left-xs">{{
                        'DETAIL.STATUS.METER_READINGS_COMPLETE.CONTACT_US' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard
                    }}</a>
                </div>
            </div>
        </div>
    </app-todo-list>

    <app-todo-list [label]="'ENERGY.ENERGY_METER_INFO.LABEL' | translate">
        <ng-container todo-list-header-right>
            <smvd-ui-energy-meter-state-pill
                [meterState]="move?.track?.energyMeterReadings?.energyMetersState"
                [todo]="MoveUtils.checkForTodo(move, TodoType.meterInfoNotComplete)"
            ></smvd-ui-energy-meter-state-pill>
        </ng-container>
        <ng-container todo-list-body>
            <ng-container [ngSwitch]="move?.track?.energyMeterReadings?.energyMetersState">
                <ng-container *ngSwitchCase="energyMetersState.Incomplete" [ngTemplateOutlet]="energyMeterInfoBodyIncomplete">
                </ng-container>
                <ng-container *ngSwitchCase="energyMetersState.Completed" [ngTemplateOutlet]="energyMeterInfoBodyCompleted"></ng-container>
                <ng-container *ngSwitchCase="energyMetersState.Confirmed" [ngTemplateOutlet]="energyMeterInfoBodyConfirmed"></ng-container>
                <ng-container *ngSwitchCase="energyMetersState.Processed" [ngTemplateOutlet]="energyMeterInfoBodyProcessed"></ng-container>
            </ng-container>
        </ng-container>
    </app-todo-list>

    <ng-template #energyMeterInfoBodyIncomplete>
        <p class="u-color-gray-dark u-margin-bottom-md">{{ 'ENERGY.ENERGY_METER_INFO.INCOMPLETE.LABEL' | translate }}</p>
        <button
            (click)="openMeterInfo()"
            [appearance]="buttonAppearance.Default"
            [context]="uiContext.PrimaryDark"
            [size]="buttonSize.Default"
            id="cta-incomplete-open-meter-info"
            class="u-font-weight-semi-bold"
            smvd-ui-button
        >
            {{ 'ENERGY.ENERGY_METER_INFO.INCOMPLETE.CTA' | translate }}
        </button>
    </ng-template>

    <ng-template #energyMeterInfoBodyCompleted>
        <p class="u-color-gray-dark u-margin-bottom-md">{{ 'ENERGY.ENERGY_METER_INFO.COMPLETED.LABEL' | translate }}</p>
        <button
            (click)="confirmMeterReadings()"
            [appearance]="buttonAppearance.Default"
            [context]="uiContext.PrimaryDark"
            [size]="buttonSize.Default"
            id="cta-completed-confirm-meter-info"
            class="u-font-weight-semi-bold"
            smvd-ui-button
        >
            {{ 'ENERGY.ENERGY_METER_INFO.COMPLETED.CONFIRM_METER_READINGS' | translate }}
        </button>
        <button
            (click)="openMeterInfo()"
            [appearance]="buttonAppearance.Default"
            [context]="uiContext.Alt"
            [size]="buttonSize.Default"
            id="cta-completed-open-meter-info"
            class="u-margin-left u-font-weight-semi-bold"
            smvd-ui-button
        >
            {{ 'ENERGY.ENERGY_METER_INFO.COMPLETED.VIEW_METER_READINGS' | translate }}
        </button>
    </ng-template>

    <ng-template #energyMeterInfoBodyConfirmed>
        <p class="u-color-gray-dark u-margin-bottom-md">{{ 'ENERGY.ENERGY_METER_INFO.CONFIRMED.LABEL' | translate }}</p>
        <button
            (click)="openMeterInfo()"
            [appearance]="buttonAppearance.Default"
            [context]="uiContext.Alt"
            [size]="buttonSize.Default"
            id="cta-confirmed-open-meter-info"
            class="u-font-weight-semi-bold"
            smvd-ui-button
        >
            {{ 'ENERGY.ENERGY_METER_INFO.CONFIRMED.CTA' | translate }}
        </button>
    </ng-template>

    <ng-template #energyMeterInfoBodyProcessed>
        <p class="u-color-gray-dark u-margin-bottom-md">{{ 'ENERGY.ENERGY_METER_INFO.PROCESSED.LABEL' | translate }}</p>
        <button
            (click)="openMeterInfo()"
            [appearance]="buttonAppearance.Default"
            [context]="uiContext.Alt"
            [size]="buttonSize.Default"
            id="cta-processed-view-meter-info"
            class="u-font-weight-semi-bold"
            smvd-ui-button
        >
            {{ 'ENERGY.ENERGY_METER_INFO.PROCESSED.VIEW_METER_READINGS' | translate }}
        </button>
        <a
            [href]="moveSandbox.meterInfoTransfereeContactUsLink$ | async"
            [appearance]="buttonAppearance.LinkReversed"
            [context]="uiContext.PrimaryDark"
            [size]="buttonSize.Default"
            id="cta-processed-contact-us"
            class="u-margin-left u-padding-x-axis-none"
            smvd-ui-button
        >
            <span class="u-font-weight-semi-bold">
                {{ 'ENERGY.ENERGY_METER_INFO.PROCESSED.CONTACT_US' | translate }}
            </span>
        </a>
    </ng-template>

    <app-todo-list
        *ngIf="move.track.trackTransferee"
        [completed]="move.track.transferee.completed === todoState.Checked"
        [label]="moverRole.Transferee | i18nKeyTranslate: i18nKeys.MoverRole"
        [subIfComplete]="move.track.lastModifiedOn.transfereeComplete | formatDate"
        class="u-margin-top"
    >
        <smvd-app-todo-setting-the-scene [move]="move" todo></smvd-app-todo-setting-the-scene>
        <app-todo-energy-eots-sent *ngIf="showEotsSent; else invite" [move]="move" todo></app-todo-energy-eots-sent>

        <ng-template #invite>
            <app-todo-energy-invited [move]="move" todo></app-todo-energy-invited>
        </ng-template>

        <app-todo-energy-ordered [move]="move" todo></app-todo-energy-ordered>
        <app-todo-energy-ordered-by-admin [move]="move" todo></app-todo-energy-ordered-by-admin>
        <app-todo-energy-tansfer-meter-readings-sent [move]="move" todo></app-todo-energy-tansfer-meter-readings-sent>
    </app-todo-list>

    <app-todo-list
        *ngIf="move.track.trackLeaver"
        [completed]="energyStopStore.energyStop()?.status === ServiceStatus.Submitted"
        [label]="moverRole.Leaver | i18nKeyTranslate: i18nKeys.MoverRole"
        class="u-margin-top"
    >
        <smvd-app-todo-leaver-not-interested
            *ngIf="!energyStopStore.energyStop()?.active; else interested"
            todo
        ></smvd-app-todo-leaver-not-interested>
        <ng-template #interested>
            <app-todo-leaver-created [move]="move" todo></app-todo-leaver-created>
            <app-todo-leaver-suppliers-received
                [move]="move"
                [energyStop]="energyStopStore.energyStop()"
                todo
            ></app-todo-leaver-suppliers-received>
            <app-todo-leaver-regularization-bill-requested
                [move]="move"
                [energyStop]="energyStopStore.energyStop()"
                todo
            ></app-todo-leaver-regularization-bill-requested>
        </ng-template>
    </app-todo-list>
</ng-container>
