import { MoverRole } from '@app/move/enums/mover-role.enum';
import { Service } from '@app/move/enums/service.enum';
import { TodoType } from '@app/move/enums/todo-type.enum';
import { Move } from '@app/move/interfaces/move';
import { MoveUtils } from '@app/move/state/move.utils';
import { FeatureScope } from '@smooved/core';

export enum MoveDetailTab {
    energy = 'energy',
    telecom = 'telecom',
    water = 'water',
    insurances = 'insurances',
    contactLogs = 'contact-logs',
    nps = 'nps',
    boilerMaintenance = 'boilerMaintenance',
}

const i18nKeys = {
    energy: 'ENERGY.LABEL',
    telecom: 'TELECOM.LABEL',
    water: 'WATER.LABEL',
    insurances: 'INSURANCES.LABEL',
    boilerMaintenance: 'SERVICES.BOILER_MAINTENANCE.TITLE',
};

export const availableServices = [
    {
        service: Service.energy,
        tab: MoveDetailTab.energy,
        label: i18nKeys.energy,
        isVisible: () => true,
        todoList: [
            TodoType.energyOptimizationNotOrdered,
            TodoType.energyNotOrdered,
            TodoType.energyNotOrderedBySmooved,
            TodoType.meterInfoNotComplete,
        ],
    },
    {
        service: Service.telecom,
        tab: MoveDetailTab.telecom,
        label: i18nKeys.telecom,
        isVisible: () => true,
        todoList: [],
    },
    {
        service: Service.water,
        tab: MoveDetailTab.water,
        label: i18nKeys.water,
        isVisible: (move: Move) => move.user?.role !== MoverRole.Leaver,
        todoList: [TodoType.waterDocumentsMovingAddressByRealEstateAgent],
    },
    {
        service: Service.boilerMaintenance,
        tab: MoveDetailTab.boilerMaintenance,
        label: i18nKeys.boilerMaintenance,
        isVisible: (move: Move) => move.user?.role !== MoverRole.Leaver,
        todoList: [],
    },
    {
        service: Service.insurances,
        tab: MoveDetailTab.insurances,
        label: i18nKeys.insurances,
        isVisible: (move: Move) => move.realEstateGroupServices?.insurances && !!move.insurancesStates,
        todoList: [],
    },
];

export const defaultActive = MoveDetailTab.energy;
