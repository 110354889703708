import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { MoverRole } from '@app/real-estate-agent/enums/mover-role.enum';
import { RealEstateAgentModalsSandbox } from '@app/real-estate-agent/services/real-estate-agent-modals.sandbox';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { TranslateService } from '@ngx-translate/core';
import { ButtonAppearance, ButtonSize, ModalSandbox, TodoState, TodoUtils, UiContext, UiHeaderVariant, UiIconSize } from '@smooved/ui';
import { CreatedByFlowEnum, DbUtils, I18nKeyType, PostHogEventsEnum, RxjsComponent, EnergyMetersState, inject } from '@smooved/core';
import { filter, map, takeUntil } from 'rxjs/operators';
import { overalStatesI18n } from './move-track-energy.constants';
import { TodoType } from '@app/move/enums/todo-type.enum';
import { PosthogService } from '@app/tracking/posthog.service';
import { EnergyStopStore } from '@app/services/energy-stop/store/energy-stop.store';
import { EnergyStopSuppliersStatus } from '@app/services/energy-stop/enums/energy-stop-suppliers-status';
import { ServiceStatus } from '@app/services/enums/service-status.enum';

@Component({
    selector: 'app-move-track-energy',
    templateUrl: './move-track-energy.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveTrackEnergyComponent extends RxjsComponent implements OnInit {
    public move: Move;
    public leaver: Move;
    public overalStateI18n: string;
    public showEotsSent: boolean;
    public showFurtherAssistanceLabel: boolean = true;

    public readonly overalStateLabelI8n = overalStatesI18n.transferComplete;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly uiContext = UiContext;
    public readonly headerVariant = UiHeaderVariant;
    public readonly iconSize = UiIconSize;
    public readonly i18nKeys = { ...I18nKeyType, ...AppI18nKeyType };
    public readonly moverRole = MoverRole;
    public readonly todoState = TodoState;
    public readonly energyMetersState = EnergyMetersState;
    public readonly MoveUtils = MoveUtils;
    public readonly TodoType = TodoType;

    public readonly energyStopStore = inject(EnergyStopStore);

    constructor(
        public readonly moveSandbox: MoveSandbox,
        private readonly modalSandbox: ModalSandbox,
        private readonly realEstateAgentModalSandbox: RealEstateAgentModalsSandbox,
        private readonly translateService: TranslateService,
        private readonly cdr: ChangeDetectorRef,
        private readonly posthogService: PosthogService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.moveSandbox.move$.pipe(filter(this.keepMove), map(this.getTransferee), takeUntil(this.destroy$)).subscribe(this.handleMove);
    }

    public openMeterInfo(): void {
        this.realEstateAgentModalSandbox.openMeterInfoModal();
    }

    public confirmMeterReadings(): void {
        const config = {
            header: this.translateService.instant('ENERGY.ENERGY_METER_INFO.COMPLETED.CONFIRM_HEADER'),
            question: this.translateService.instant('ENERGY.ENERGY_METER_INFO.COMPLETED.CONFIRM_INFO'),
        };
        this.modalSandbox.openConfirmModal(
            config,
            (result) => this.confirmMeterReadingsHandler(result),
            config,
            (result) => this.confirmMeterReadingsHandler(result)
        );
    }

    private confirmMeterReadingsHandler(result: boolean) {
        if (!result) return;

        this.moveSandbox.patchProperty(
            '',
            {
                legal: {
                    meterReadingsTakeover: true,
                },
                energyReadingsConfirmedByRealEstateAgent: true,
            },
            true,
            (move: Move) => {
                this.moveSandbox.energyMeterReadingsConfirmed(DbUtils.getStringId(move));
            },
            false,
            this.move,
            true
        );
    }

    private getTransferee = (move: Move): Move => {
        const { transferee, leaver } = MoveUtils.getMovers(move);
        this.move = transferee;
        this.leaver = leaver;
        return transferee;
    };

    private keepMove = (move: Move): boolean => !!move?.track && this.hasTransferee(move);

    private hasTransferee(move: Move): boolean {
        return (
            move?.user &&
            (move.user.role === MoverRole.Transferee || (move.user.role === MoverRole.Leaver && !!(move.linkedMove as Move)._id))
        );
    }

    private handleMove = (move: Move): void => {
        this.showEotsSent =
            MoveUtils.isCreatedByFlow(move, CreatedByFlowEnum.Eots) ||
            MoveUtils.isCreatedByFlow(move, CreatedByFlowEnum.EnergyOptimization);
        this.setOveralState(move);
        this.energyStopStore.load(DbUtils.getStringId(move));
        this.cdr.markForCheck();
    };

    private setOveralState = (move: Move): void => {
        this.overalStateI18n = this.getOveralStateByMove(move);
    };

    private getOveralStateByMove(move: Move): string {
        const { trackMeterReadings, energyMeterReadings, transferee, leaver } = move.track;
        const isCreatedByFlowEnergyOptimization = MoveUtils.isCreatedByFlow(move, CreatedByFlowEnum.EnergyOptimization);
        const transfereeMove = MoveUtils.getMovers(move).transferee;

        // State: No meter Readings
        if (trackMeterReadings && TodoUtils.isUnchecked(energyMeterReadings.completed)) {
            this.showFurtherAssistanceLabel = false;
            return overalStatesI18n.meterReadingsNotComplete;
        }

        // State: Transferee not interested
        if (TodoUtils.isBlocked(transferee.energyOfferConfirmed)) {
            if (isCreatedByFlowEnergyOptimization) return overalStatesI18n.meterReadingsComplete.energyNoInterest.isEnergyOptimization;
            if (TodoUtils.isUnchecked(leaver.created)) return overalStatesI18n.meterReadingsComplete.energyNoInterest.noLeaver;
            if (this.energyStopStore.energyStop()?.states.suppliersStatus === EnergyStopSuppliersStatus.Complete)
                return this.getByProgress(
                    overalStatesI18n.meterReadingsComplete.energyNoInterest.withSuppliers,
                    this.energyStopStore.energyStop()?.isActiveAndSubmitted() ? TodoState.Checked : TodoState.Unchecked
                );
            return overalStatesI18n.meterReadingsComplete.energyNoInterest.noSuppliers;
        }

        // State: Transferee Confirmed
        if (TodoUtils.isChecked(transferee.energyOfferConfirmed)) {
            if (isCreatedByFlowEnergyOptimization)
                return this.getByProgress(
                    overalStatesI18n.meterReadingsComplete.energyConfirmed.isEnergyOptimization,
                    transferee.energyTransferMeterReadingsSentByAdmin
                );

            if (TodoUtils.isUnchecked(leaver.created))
                return this.getByProgress(
                    overalStatesI18n.meterReadingsComplete.energyConfirmed.noLeaver,
                    transferee.energyTransferMeterReadingsSentByAdmin
                );

            if (this.energyStopStore.energyStop()?.states.suppliersStatus === EnergyStopSuppliersStatus.Complete)
                return this.getByProgress(
                    overalStatesI18n.meterReadingsComplete.energyConfirmed.withSuppliers,
                    transferee.energyTransferMeterReadingsSentByAdmin
                );

            return this.getByProgress(
                overalStatesI18n.meterReadingsComplete.energyConfirmed.noSuppliers,
                transferee.energyTransferMeterReadingsSentByAdmin
            );
        }

        // State: Transferee Open interest
        if (isCreatedByFlowEnergyOptimization) return overalStatesI18n.meterReadingsComplete.energyOpenInterest.isEnergyOptimization;
        if (TodoUtils.isUnchecked(leaver.created)) return overalStatesI18n.meterReadingsComplete.energyOpenInterest.noLeaver;
        if (this.energyStopStore.energyStop()?.states.suppliersStatus === EnergyStopSuppliersStatus.Complete)
            return this.getByProgress(
                overalStatesI18n.meterReadingsComplete.energyOpenInterest.withSuppliers,
                this.energyStopStore.energyStop()?.isActiveAndSubmitted() ? TodoState.Checked : TodoState.Unchecked
            );
        return overalStatesI18n.meterReadingsComplete.energyOpenInterest.noSuppliers;
    }

    private getByProgress(value: { requested: string; progress: string }, state: TodoState): string {
        return TodoUtils.isChecked(state) ? value.requested : value.progress;
    }

    protected readonly ServiceStatus = ServiceStatus;
}
